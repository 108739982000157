import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Row, Col } from "react-bootstrap";

import * as s from "./Player.module.scss";

import WaveformPlayer from "../WaveformPlayer";

const Player = function ({ logo, title, file }) {
  return (
    <div className={`${s.card} mb-3`}>
      <Row className="g-0">
        <Col xs={3} className="p-3">
          {logo && (
            <GatsbyImage
              className="img-fluid rounded-start"
              alt={title}
              image={logo.localFile.childImageSharp.gatsbyImageData}
            />
          )}
        </Col>
        <Col xs={9}>
          <div className={s.cardBody}>
            <h5 className={s.cardTitle}>{title}</h5>
            {file && <WaveformPlayer file={file} />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

Player.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  file: PropTypes.string,
};

export default Player;
