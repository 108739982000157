import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";

const WaveformPlayer = function ({ file }) {
  const [playing, setPlaying] = useState(false);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
    }
  };
  useEffect(() => {
    if (file !== "") {
      if (waveformRef.current) {
        const settings = {
          container: waveformRef.current,
          waveColor: "#e0dfef",
          progressColor: "#524e9a",
          cursorColor: "#e0dfef",
          barWidth: 2,
          barRadius: 3,
          responsive: true,
          height: 75,
          normalize: true,
          hideScrollbar: true,
        };
        wavesurfer.current = WaveSurfer.create(settings);
        wavesurfer.current.load(file);

        wavesurfer.current.on("finish", () => {
          setPlaying(false);
        });
      }
    }
    return () => {
      // console.log("destroying wavesurfer instance");
      if (wavesurfer.current) wavesurfer.current.destroy();
    };
  }, [file]);

  return (
    <div className="d-flex flex-row align-items-center">
      <button
        type="button"
        className="btn btn-light shadow-none me-4"
        onClick={handlePlayPause}
      >
        <i
          className={`fas ${
            playing ? "fa-pause" : "fa-play"
          } fa-2x text-primary text-center`}
          style={{ width: "0.5em" }}
        />
      </button>
      <div ref={waveformRef} className="w-100" />
    </div>
  );
};

WaveformPlayer.propTypes = {
  file: PropTypes.string.isRequired,
};

export default WaveformPlayer;
