import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import Player from "../components/Player";
import CallToAction from "../components/CallToAction";
import ServicesList from "../components/ServicesList";

const DemoList = function ({ list }) {
  return (
    <Row>
      {list.map(({ id, logo, title, file, videoUrl }) => {
        let player;
        if (file != null) {
          player = (
            <Player logo={logo} title={title} file={file.localFile.publicURL} />
          );
        } else if (videoUrl != null) {
          player = (
            <iframe
              width="560"
              height="315"
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          );
        }
        return (
          <Col lg={6} key={`demo-${id}`} className="mb-3">
            {player}
          </Col>
        );
      })}
    </Row>
  );
};

DemoList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logo: PropTypes.oneOfType([PropTypes.object]).isRequired,
      title: PropTypes.string.isRequired,
      file: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

const ServiceTemplate = function ({ data: { appSetting, service, services } }) {
  return (
    <Layout appSettingLocale={appSetting}>
      <Hero
        title={service.hero?.title ? service.hero.title : service.title}
        description={service.hero?.description && service.hero.description}
        background={
          service.hero?.background &&
          service.hero.background.localFile.publicURL
        }
      />

      <section className="ttm-row-page">
        <Container>
          <div className="text-center mb-3">
            <GatsbyImage
              className="me-4 align-middle"
              alt={service.serviceCard.title}
              image={
                service.serviceCard.icon.localFile.childImageSharp
                  .gatsbyImageData
              }
            />
            <h1 className="d-inline-block">{service.serviceCard.title}</h1>
          </div>
          {parse(service.content.data.content)}
          <h2 className="text-center my-5">{service.demosTitle}</h2>
          <DemoList list={service.demos} />
        </Container>
      </section>

      <CallToAction
        background={appSetting.callToActionBackground}
        content={appSetting.callToActionContent.data.callToActionContent}
      />

      <section className="ttm-row bg-image-gray">
        <Container>
          <Row>
            <Col className="mx-auto mb-4">
              <header className="text-center">
                <h5>{appSetting.discoverOurOtherServices.label}</h5>
                <h2>{appSetting.discoverOurOtherServices.title}</h2>
              </header>
            </Col>
          </Row>
          <ServicesList list={services.nodes} />
        </Container>
      </section>
    </Layout>
  );
};

ServiceTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ServiceTemplate;

export const Head = ({
  location,
  data: {
    service: {
      title,
      hero: { description },
      localizations,
    },
  },
}) => (
  <Seo
    title={title}
    description={description}
    localizations={localizations.data}
    pathname={location.pathname}
  />
);

export const query = graphql`
  query ($id: String!, $locale: String!) {
    service: strapiService(id: { eq: $id }) {
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      title
      hero {
        title
        description
        background {
          name
          localFile {
            publicURL
          }
        }
      }
      content {
        data {
          content
        }
      }
      demosTitle
      demos {
        id
        title
        logo {
          localFile {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        file {
          localFile {
            publicURL
          }
        }
        videoUrl
      }
      serviceCard {
        id
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
        description
      }
    }
    services: allStrapiService(
      filter: { id: { ne: $id }, locale: { eq: $locale } }
      sort: { serviceCard: { order: ASC } }
    ) {
      nodes {
        fields {
          slug
        }
        serviceCard {
          id
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title
          description
        }
      }
    }
    appSetting: strapiAppSetting(locale: { eq: $locale }) {
      headerNav {
        strapi_json_value {
          label
          url
          subMenu {
            label
            url
          }
        }
      }
      callToActionBackground {
        localFile {
          publicURL
        }
      }
      callToActionContent {
        data {
          callToActionContent
        }
      }
      discoverOurOtherServices {
        label
        title
      }
      footerNav {
        strapi_json_value {
          label
          subMenu {
            label
            url
          }
        }
      }
      footerContactTitle
      footerContactContent {
        data {
          footerContactContent
        }
      }
      copyrightLinks {
        strapi_json_value {
          label
          url
        }
      }
    }
  }
`;
